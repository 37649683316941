import React from "react";
import Signpost from "../components/Signpost/Signpost";
import MountingContent from "../components/MoutingContent/MountingContent";

function Mounting() {
	return (
		<>
			<MountingContent />
			<div className="text-side">
				<h1>Mounting</h1>
				<ul className="list">
					<li>
						DIN rail directly
					</li>
					<li>
						Flat bracket
					</li>
					<li>
						L bracket
					</li>
					<li>
						19″ rack chassis
					</li>
				</ul>
				<Signpost prev={{ to: '/connectivity', title: 'Connectivity'}} next={{ to: '/', title: 'Home'}} />
			</div>
		</>
	);
}

export default Mounting;
