import React from "react";
import Signpost from "../components/Signpost/Signpost";

function MarketLeader() {
	return (
		<div className="text-side">
			<h1>Market leader</h1>
			<ul className="list">
				<li>
					1.7 Mb/s, 256 QAM
				</li>
				<li>
					Dynamic routing
				</li>
				<li>
					Radio and Cellular in one
				</li>
				<li>
					Unlimited coverage without Base stations
				</li>
			</ul>
			<Signpost prev={{ to: '/', title: 'Home'}} next={{ to: '/performance', title: 'Performance'}} />
		</div>
	);
}

export default MarketLeader;
