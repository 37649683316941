import React from "react";
import Signpost from "../components/Signpost/Signpost";

function Performance() {
	return (
		<div className="text-side">
			<h1>Performance</h1>
			<ul className="list">
				<li>
					160, 300, 400, 800, 900 MHz
				</li>
				<li>
					CPFSK – 256 QAM
				</li>
				<li>
					6.25 – 300 kHz channels
				</li>
				<li>
					10 W, Half or Full duplex
				</li>
			</ul>
			<Signpost prev={{ to: '/market-leader', title: 'Market Leader'}} next={{ to: '/security', title: 'Security'}} />
		</div>
	);
}

export default Performance;
