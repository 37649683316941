import React from "react";
import './Spinner.scss';

function Spinner() {
	return (
		<svg className="spinner">
			<use xlinkHref="#spinner"/>
		</svg>
	);
}

export default Spinner;
