import React from "react";
import PropTypes from 'prop-types';
import './Button.scss';

function Button({text, to}) {
	return (
		<a href={to} type="button" className="button" target="_blank" rel="noreferrer">
			{text}
		</a>
	);
}

Button.propTypes = {
	text: PropTypes.string.isRequired,
	to: PropTypes.string.isRequired,
};

export default Button;
