import React from 'react';
import HomeMenu from "../components/HomeMenu/HomeMenu";

function Home() {
	return	(
		<>
		<div className="title-wrap">
		<h1 className="title">RipEX – The Fastest Radio Modem</h1>
		</div>
		<HomeMenu />
		</>
	);
}

export default Home;
