import React, { useRef, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import * as THREE from 'three';
import { useFrame, useLoader } from 'react-three-fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import './Model.scss';

function Mesh({
 setLoaded,
	page,
}) {
	const [animateY, setAnimateY] = useState(false);
	const [rotationX, setRotationX] = useState(0);
	const [rotationY, setRotationY] = useState(0);
	const [rotationZ, setRotationZ] = useState(0);
	const [scaler, setScaler] = useState(380);
	const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
	const mesh = useRef();
	const objUrl = process.env.PUBLIC_URL + '/Rip2.glb';
	const object = useLoader(GLTFLoader, objUrl)?.scene;

	useEffect(() => {
		const updateDimensions = () => {
			setViewportWidth(window.innerWidth);
		}

		window.addEventListener("resize", updateDimensions);
		return () => window.removeEventListener("resize", updateDimensions);
	}, []);

	const scalerSize = (size) => {
		let multiplicator = 0.26;
		if (size < 1056) {
			multiplicator = 0.9;
		}
		if (size < 500) {
			multiplicator = 1.3;
		}
		return size * multiplicator > 500 ? 500 : size * multiplicator;
	}

	useEffect(() => {
		setLoaded(true);
	}, [object, setLoaded]);

	useEffect(() => {
		setRotationX(0);
		setRotationY(0);
		setRotationZ(0);

		if (mesh.current) {
			mesh.current.rotation.x = 0;
			mesh.current.rotation.y = 0;
			mesh.current.rotation.z = 0;
		}

		if (page === '/market-leader' || page === '/performance' || page === '/mounting') {
			setAnimateY(true);
			setRotationX(Math.PI / 30);
			setRotationY(Math.PI / -10);
			setRotationZ(0);
			setScaler(scalerSize(viewportWidth));
		} else if (page === '/reliability') {
			setAnimateY(true);
			setRotationX(0);
			setRotationY(0);
			setRotationZ(Math.PI / 2);
			setScaler(350);
		} else if (page === '/connectivity') {
			setAnimateY(false);
			setRotationX(0);
			setRotationY(0);
			setRotationZ(0);
			setScaler(540);
		} else if (page === '/security') {
			setAnimateY(false);
			setRotationX(Math.PI / 40);
			setRotationY(Math.PI / -15);
			setRotationZ(0);
			setScaler(scalerSize(viewportWidth));
		} else {
			setAnimateY(false);
			setRotationX(Math.PI / 20);
			setRotationY(Math.PI / -10);
			setRotationZ(0);
			setScaler(scalerSize(viewportWidth));
		}
	}, [page, viewportWidth, object, rotationX, rotationY, rotationZ, scaler]);

	useFrame(() => {
		if (animateY && mesh.current) {
			mesh.current.rotation.y += 0.005;
		}
	});

	const onClick = useCallback(
		e => {
			e.stopPropagation();
			setAnimateY(false);
		},
		[setAnimateY]
	);

	useEffect(() => {
		if (mesh.current) {
			mesh.current.rotation.x = 0;
			mesh.current.rotation.y = 0;
			mesh.current.rotation.z = 0;
		}
		setTimeout(() => {
			const box = new THREE.Box3().setFromObject(object);
			const center = new THREE.Vector3();
			box.getCenter(center);
			object.position.sub(center);

			object.rotation.x = rotationX;
			object.rotation.y = rotationY;
			object.rotation.z = rotationZ;
			object.scale.set(scaler, scaler, scaler);

			const boxNew = new THREE.Box3().setFromObject(object);
			const centerNew = new THREE.Vector3();
			boxNew.getCenter(centerNew);
			object.position.sub(centerNew);
		}, 50)
	}, [object, rotationX, rotationY, rotationZ, scaler]);

	return (
		<mesh
			ref={mesh}
			onClick={e => onClick(e)}
			onPointerDown={e => onClick(e)}
		>
			{(page !== '/performance') ? (
				<primitive object={object} />
			) : null}
		</mesh>
	);
}

Mesh.propTypes = {
	animateY: PropTypes.bool,
	rotationX: PropTypes.number,
	rotationY: PropTypes.number,
	setLoaded: PropTypes.func.isRequired,
	fixedScaler: PropTypes.number,
	page: PropTypes.string.isRequired,
};

Mesh.defaultProps = {
	animateY: false,
	rotationX: 20,
	rotationY: -10,
	fixedScaler: null,
};

export default Mesh;
