import React, { useState } from "react";
import './Header.scss';
import Nav from "../Nav/Nav";
import Button from "../Button/Button";
import {Link} from "react-router-dom";

function Header() {
	const [navOpen, setNavOpen] = useState(false);

	const toggleNav = () => {
		setNavOpen(!navOpen);
	};

	let headerInnerClass = 'header__inner';
	if (navOpen) {
		headerInnerClass += ' is-open';
	}

	let headerClass = 'header';

	return (
		<div className={headerClass}>
			<div className={headerInnerClass}>
				<Link to="/" className="header__logo">
					<svg xmlns="http://www.w3.org/2000/svg" width="97" height="18" viewBox="0 0 97 18"><path fill="#bd1127" d="M76.669.14l-5.152 4.604L66.365.14H61.62l7.556 6.688-7.556 6.727h4.657l5.239-4.65 5.24 4.65h4.658l-7.557-6.727L81.414.14zM18.284 13.55h-3.711L9.034 7.112l3.8.002a2.198 2.198 0 002.19-2.094 2.196 2.196 0 00-2.19-2.093H2.784V13.55H0V.14h12.833a4.985 4.985 0 014.976 4.88 4.981 4.981 0 01-3.08 4.502zm-.469-8.427c0-.036-.003-.069-.006-.103.003-.036.006-.069.006-.103z"/><path fill="#7c7c7c" d="M20.97 13.537h2.673V3.89H20.97zM20.97 2.444h2.673V.15H20.97z"/><path fill="#bd1127" d="M47.602 2.927h11.419V.14H47.602a4.989 4.989 0 00-4.982 4.983v3.442a4.988 4.988 0 004.982 4.981h11.419v-2.785H47.602a2.198 2.198 0 01-2.196-2.196v-.329h13.615V5.45H45.406v-.327c0-1.212.984-2.196 2.196-2.196"/><path fill="#7c7c7c" d="M35.548 3.87H26.38v14.129h2.671V6.546h6.497a2.11 2.11 0 012.107 2.107v.113a2.11 2.11 0 01-2.107 2.108h-2.129v2.674h2.129a4.788 4.788 0 004.781-4.782v-.113a4.788 4.788 0 00-4.781-4.783"/><path fill="#bd1127" d="M96.246 10.757v2.776H84.5l6.025-6.24c.755-.768 1.317-1.415 1.684-1.933.368-.519.55-.988.55-1.409 0-.531-.198-.943-.597-1.24-.395-.297-.945-.443-1.637-.443-.648 0-1.166.187-1.553.558-.388.372-.578.878-.578 1.519v.065h-3.416v-.228c0-1.343.483-2.373 1.452-3.099C87.398.362 88.762 0 90.525 0c1.21 0 2.246.147 3.104.444.858.296 1.506.721 1.953 1.277.443.556.664 1.218.664 1.992 0 .491-.095.969-.289 1.438a5.985 5.985 0 01-.822 1.395 16.13 16.13 0 01-1.142 1.328 86.438 86.438 0 01-1.906 1.908l-.988.972z"/></svg>
				</Link>
				<button type="button" className="header__toggle" onClick={toggleNav}>
					Open menu
					{navOpen ? (
						<svg viewBox="0 0 14 14" width="14" height="14" xmlns="http://www.w3.org/2000/svg"><path fill="#bd1127" d="M13 10.88a.999.999 0 010 1.413l-.707.707a.999.999 0 01-1.414 0L6.646 8.768 2.414 13A.999.999 0 011 13l-.707-.707a.999.999 0 010-1.414l4.232-4.232L.293 2.414A.999.999 0 01.293 1L1 .293a.999.999 0 011.414 0l4.232 4.232L10.88.293a.999.999 0 011.414 0L13 1a.999.999 0 010 1.414L8.768 6.647z"/></svg>
					) : (
						<svg viewBox="0 0 4 16" width="4" height="16" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2"><circle cx="2" cy="6" r="2" fill="#bd1127" transform="translate(0 8)"/><circle cx="2" cy="6" r="2" fill="#bd1127" transform="translate(0 2)"/><circle cx="2" cy="6" r="2" fill="#bd1127" transform="translate(0 -4)"/></svg>
					)}
				</button>
				<div className="header__title">3D Presentation</div>
				<div className="header__nav">
					<a href="https://www.racom.eu/eng/products/radio-modem-ripex.html" className="header__link" target="_blank" rel="noreferrer">
						Product Page
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path fill="#bd1127" d="M10.332 3.141L5.515 9.564c-.14.14-.07.349 0 .489l.489.488c.14.14.349.14.488 0l6.423-4.817L14.38 7.19c.21.21.558.07.558-.209L15.986.419c.07-.21-.14-.419-.418-.419L9.005 1.047c-.279.07-.418.419-.209.559zm2.932 10.402c0 .21-.14.35-.35.35H2.445c-.21 0-.35-.14-.35-.35V3.072c0-.21.14-.35.35-.35h5.514L5.864.629h-3.42C1.116.628 0 1.745 0 3.072v10.471c0 1.327 1.117 2.443 2.443 2.443h10.472c1.326 0 2.443-1.116 2.443-2.443v-3.42l-2.094-2.095z"/></svg>
					</a>
					<Button
						text="Get Quote"
						to="https://www.racom.eu/eng/products/rfp.html#load(product=ripex)"
					/>
				</div>
			</div>
			<Nav open={navOpen} setNavOpen={setNavOpen} />
		</div>
	);
}

export default Header;
