import React from 'react';
import {Route, Switch, useRouteMatch} from 'react-router-dom';
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import {Connectivity, Reliability, Home, MarketLeader, Mounting, Performance, Security} from './pages';
import ModelWrap from "./components/Model/ModelWrap";
import './App.scss';

function App() {
	let isConnectivity = useRouteMatch({
		path: '/connectivity',
		exact: true
	});
	let wrapClass = 'wrap';
	if (!!isConnectivity) {
		wrapClass += ' wrap-scroll';
	}

	return (
		<div className={wrapClass}>
			<svg xmlns="http://www.w3.org/2000/svg">
				<symbol id="plus" viewBox="0 0 22 22">
					<path d="M10.979 21.957c6.063 0 10.978-4.915 10.978-10.979C21.957 4.916 17.042 0 10.978 0 4.916 0 0 4.915 0 10.979c0 6.063 4.915 10.978 10.979 10.978z" fill="#fff" fillRule="nonzero"/>
					<path d="M10.979 19.967a8.988 8.988 0 100-17.976 8.988 8.988 0 100 17.976zm1.239-13.863c.079.08.125.193.125.33v3.18h3.18c.274 0 .456.183.456.456v1.819c0 .272-.182.454-.455.454h-3.181v3.18c0 .274-.182.456-.455.456H10.07c-.273 0-.455-.182-.455-.455v-3.181H6.434c-.273 0-.455-.182-.455-.455V10.07c0-.273.182-.455.455-.455h3.18V6.434c0-.273.183-.455.456-.455h1.818l.33.125z" style={{ fill: 'currentColor' }}/>
				</symbol>
				<symbol id="arrow" viewBox="0 0 8 13">
					<path d="M.292 6.145l6-6a.502.502 0 01.707 0l.707.707a.502.502 0 010 .707L2.766 6.5l4.941 4.94a.502.502 0 010 .706L7 12.852a.502.502 0 01-.707 0l-6-6a.502.502 0 010-.707" fill="#bd1127" fillRule="nonzero"/>
				</symbol>
				<symbol id="line" viewBox="0 0 10 4" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="1.5">
					<path vectorEffect="non-scaling-stroke" d="M.728.799h8.538" fill="none" stroke="#fff" strokeWidth="4" />
					<path vectorEffect="non-scaling-stroke" d="M.728.799h8.538" fill="none" stroke="#bd1127" strokeWidth="2" />
				</symbol>
				<symbol id="line-corner" viewBox="0 0 10 10" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="1.5">
					<path vectorEffect="non-scaling-stroke" d="M.728.799h8.538V9.37" fill="none" stroke="#fff" strokeWidth="4" />
					<path vectorEffect="non-scaling-stroke" d="M.728.799h8.538V9.37" fill="none" stroke="#bd1127" strokeWidth="2" />
				</symbol>
				<symbol id="spinner" viewBox="0 0 113 60">
					<path d="M.5 26.246c.163-.662.29-1.328.495-1.986 1.408-4.51 5.322-8.245 10.946-11.434 5.731-3.25 12.578-5.48 20.08-7.116 1.398-.304 2.607-.338 3.994.007 2.13.53 4.343.949 6.65 1.442-.26.075-.401.127-.556.158-1.718.351-3.501.623-5.148 1.062-6.151 1.64-11.553 3.808-14.942 7.3-2.079 2.143-2.909 4.428-2.367 6.871.599 2.699 2.871 4.914 6.085 6.84 4.437 2.657 9.942 4.306 16.024 5.374 4.371.768 8.859 1.14 13.427 1.21.158.002.317.001.474-.004.065-.002.128-.02.29-.048l-3.056-7.727L98.5 42.659 52.835 59.47l2.879-8.206c-.598-.016-1.128-.035-1.658-.043-8.046-.128-15.867-.93-23.392-2.586-7.157-1.576-13.6-3.803-18.991-6.945-1.416-.824-2.669-1.744-3.934-2.648-3.05-2.18-5.131-4.643-6.277-7.351-.386-.913-.554-1.855-.824-2.784-.04-.138-.092-.276-.138-.414v-2.247z" fill="#ccc" />
					<path d="M57.77.47c-.194.683-.388 1.366-.58 2.05-.036.126-.067.252-.11.423.455.012.854.026 1.254.032 9.353.138 18.371 1.18 26.93 3.4 2.057.533 4.11 1.106 6.004 1.806 2.599.96 5.181 1.966 7.527 3.117 6.208 3.048 10.717 6.778 12.712 11.398 2.13 4.935.778 9.607-3.533 14.008-.922.942-2.015 1.828-3.058 2.766l-23.551-7.172c.917-.38 1.756-.71 2.56-1.067 3.663-1.62 6.651-3.567 8.397-6.063 2.616-3.74 1.77-7.29-2.022-10.625-3.139-2.76-7.566-4.656-12.648-6.074-6.293-1.758-12.988-2.523-19.94-2.624-.172-.002-.346.016-.593.029l.948 1.927L40.5 4.34c.391-.095.604-.151.822-.2C46.55 2.985 51.782 1.83 57.009.673c.22-.049.417-.134.625-.202L57.77.47z" fill="#ccc" />
				</symbol>
			</svg>
			<Header />
			<div className="body">
				<div className="content">
					<ModelWrap />
					<Switch>
						<Route exact path="/">
							<Home />
						</Route>
						<Route path="/market-leader">
							<MarketLeader />
						</Route>
						<Route path="/performance">
							<Performance />
						</Route>
						<Route path="/security">
							<Security />
						</Route>
						<Route path="/connectivity">
							<Connectivity />
						</Route>
						<Route path="/reliability">
							<Reliability />
						</Route>
						<Route path="/mounting">
							<Mounting />
						</Route>
					</Switch>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default App;
