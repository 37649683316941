import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './MountingContent.scss';
import carousel1 from '../../images/carousel-1.png';
import carousel2 from '../../images/carousel-2.png';
import carousel3 from '../../images/carousel-3.png';
import carousel4 from '../../images/carousel-4.png';
import carousel5 from '../../images/carousel-5.png';
import carousel6 from '../../images/carousel-6.png';
import carousel7 from '../../images/carousel-7.png';

const settings = {
	dots: true,
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1
};

function MountingContent() {
	return (
		<div className="mounting">
			<Slider {...settings}>
				<div>
					<img src={carousel1} alt="" className="mounting-image" />
				</div>
				<div>
					<img src={carousel2} alt="" className="mounting-image" />
				</div>
				<div>
					<img src={carousel3} alt="" className="mounting-image" />
				</div>
				<div>
					<img src={carousel4} alt="" className="mounting-image" />
				</div>
				<div>
					<img src={carousel5} alt="" className="mounting-image" />
				</div>
				<div>
					<img src={carousel6} alt="" className="mounting-image" />
				</div>
				<div>
					<img src={carousel7} alt="" className="mounting-image" />
				</div>
			</Slider>
		</div>
	);
}

export default MountingContent;
