import React from "react";
import Signpost from "../components/Signpost/Signpost";
import SecurityContent from "../components/SecurityContent/SecurityContent";

function Security() {
	return (
		<>
			<div className="text-side">
				<h1>Security</h1>
				<ul className="list">
					<li>
						IPsec, AES256, RADIUS
					</li>
					<li>
						Firewall, VLAN
					</li>
					<li>
						Digitally signed FW, Secure Boot
					</li>
					<li>
						HW tamper
					</li>
				</ul>
				<Signpost prev={{ to: '/performance', title: 'Performance'}} next={{ to: '/reliability', title: 'Reliability'}} />
			</div>
			<SecurityContent />
		</>
	);
}

export default Security;
