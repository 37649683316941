import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from 'react-router-dom';
import './styles/index.scss';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
   <Router basename={process.env.PUBLIC_URL || null}>
	   <App />
   </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
