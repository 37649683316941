import React from 'react';
import { useLocation } from 'react-router-dom';
import Model from "./Model";
import Spinner from "../Spinner/Spinner";
import './Model.scss';

function ModelWrap() {
	const location = useLocation();
	const page = location.pathname;

	let rootClass = 'model';
	if (page === '/market-leader' || page === '/performance') {
		rootClass += ' model-right';
	} else if (page === '/reliability') {
		rootClass += ' model-right';
	} else if (page === '/connectivity') {
		rootClass += ' model-top';
	} else if (page === '/security') {
		rootClass += ' model-bottom';
	} else if (page === '/mounting') {
		rootClass += ' model-hidden';
	}

	return (
		<div className={rootClass}>
			<Model
				page={page}
			/>
			{page === '/' && <Spinner />}
		</div>
	);
}

export default ModelWrap;
