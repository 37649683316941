import React, { useEffect, useState } from "react";
import './SecurityContent.scss';

function SecurityContent() {
	const [hasStarted, setHasStarted] = useState(false);
	useEffect(() => {
		setHasStarted(true);
	}, [setHasStarted]);
	return (
		<div className={`security-content ${hasStarted ? 'is-visible' : ''}`}>
			<svg viewBox="0 0 860 409" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" strokeMiterlimit="20">
				<g>
					<path d="M105 209.257c57.99 0 105-46.732 105-104.378C210 47.232 162.99.5 105 .5S0 47.232 0 104.879c0 57.646 47.01 104.378 105 104.378z" fill="url(#_Radial1)" fillRule="nonzero"/>
					<path d="M105 209.257c57.99 0 105-46.732 105-104.378C210 47.232 162.99.5 105 .5S0 47.232 0 104.879c0 57.646 47.01 104.378 105 104.378z" fill="none" stroke="#a2c3c3" strokeWidth="6" strokeDasharray="10,10"/>
					<path d="M105 190.5c46.944 0 85-38.056 85-85s-38.056-85-85-85-85 38.056-85 85 38.056 85 85 85z" fill="url(#_Radial2)" fillRule="nonzero"/>
					<path d="M105 190.5c46.944 0 85-38.056 85-85s-38.056-85-85-85-85 38.056-85 85 38.056 85 85 85z" fill="none" stroke="#a2c3c3" strokeWidth="6" strokeDasharray="10,10"/>
					<path d="M105 170c35.899 0 65-29.101 65-65s-29.101-65-65-65-65 29.101-65 65 29.101 65 65 65z" fill="url(#_Radial3)" fillRule="nonzero"/>
					<path d="M105 170c35.899 0 65-29.101 65-65s-29.101-65-65-65-65 29.101-65 65 29.101 65 65 65z" fill="none" stroke="#a2c3c3" strokeWidth="6" strokeDasharray="10,10"/>
					<g>
						<path d="M86.28 109.88v109.627" fill="none" stroke="#7c7c7c" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M77.28 112.348l74-21.898" fill="none" stroke="#7c7c7c" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M139.28 79.02l8 26.237" fill="none" stroke="#7c7c7c" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M125.28 80.4l10 32.796" fill="none" stroke="#7c7c7c" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M111.28 80.48l12 39.355" fill="none" stroke="#7c7c7c" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M97.28 81.87l14 45.915" fill="none" stroke="#7c7c7c" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M83.28 81.94l16 52.474" fill="none" stroke="#7c7c7c" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M69.28 83.33l18 59.033" fill="none" stroke="#7c7c7c" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
					</g>
				</g>
				<g>
					<path fill="#ccc" fillRule="nonzero" d="M373 36h413v60H373z"/>
					<path fill="#ccc" fillRule="nonzero" d="M817 66v141h-60V66z"/>
					<path d="M373 96c8.284 0 15-13.431 15-30 0-16.569-6.716-30-15-30-8.284 0-15 13.431-15 30 0 16.569 6.716 30 15 30z" fillRule="nonzero"/>
					<path d="M373 96c8.284 0 15-13.431 15-30 0-16.569-6.716-30-15-30-8.284 0-15 13.431-15 30 0 16.569 6.716 30 15 30z" fill="none" stroke="#ccc" strokeWidth="10"/>
					<path d="M757 207c0 8.284 13.431 15 30 15 16.569 0 30-6.716 30-15 0-8.284-13.431-15-30-15-16.569 0-30 6.716-30 15z" fillRule="nonzero"/>
					<path d="M757 207c0 8.284 13.431 15 30 15 16.569 0 30-6.716 30-15 0-8.284-13.431-15-30-15-16.569 0-30 6.716-30 15z" fill="none" stroke="#ccc" strokeWidth="10"/>
					<path d="M787 96c16.569 0 30-13.431 30-30 0-16.569-13.431-30-30-30-16.569 0-30 13.431-30 30 0 16.569 13.431 30 30 30z" fill="#ccc" fillRule="nonzero"/>
					<path d="M787 96c16.569 0 30-13.431 30-30 0-16.569-13.431-30-30-30-16.569 0-30 13.431-30 30 0 16.569 13.431 30 30 30z" fill="none" stroke="#ccc" strokeWidth="10"/>
					<g>
						<path fill="#7c7c7c" fillRule="nonzero" d="M525.931 48.3h8.16v34.992h-8.16z"/>
						<path d="M548.683 71.58v11.712h-8.112V48.3h12.336c2.448 0 4.56.336 6.336.912 1.776.576 3.216 1.392 4.368 2.4 1.152 1.008 2.016 2.208 2.544 3.6s.816 2.928.816 4.512c0 1.776-.288 3.36-.816 4.8-.576 1.488-1.44 2.736-2.592 3.744-1.152 1.056-2.592 1.872-4.368 2.448-1.776.576-3.888.864-6.288.864h-4.224zm0-6.096h4.224c2.112 0 3.648-.528 4.56-1.536.912-1.008 1.392-2.4 1.392-4.224 0-.768-.096-1.488-.336-2.16-.24-.672-.624-1.2-1.104-1.68-.48-.48-1.104-.864-1.872-1.104-.72-.24-1.632-.384-2.64-.384h-4.224v11.088z" fill="#7c7c7c" fillRule="nonzero"/>
						<path d="M586.411 63.852c-.192.288-.384.528-.624.624-.192.144-.48.192-.816.192-.336 0-.72-.048-1.056-.192-.336-.144-.72-.336-1.152-.528-.384-.144-.864-.336-1.344-.48a6.622 6.622 0 00-1.776-.24c-.96 0-1.68.192-2.16.576-.528.336-.768.864-.768 1.488 0 .48.144.864.48 1.152.336.288.768.576 1.344.816.528.24 1.152.432 1.824.624.672.24 1.392.48 2.112.72.72.24 1.44.576 2.112.912a9.782 9.782 0 011.872 1.248 7 7 0 011.296 1.824c.336.768.48 1.584.48 2.592 0 1.248-.24 2.4-.672 3.408a6.536 6.536 0 01-2.016 2.688c-.864.768-1.968 1.344-3.264 1.776-1.296.432-2.832.624-4.512.624-.864 0-1.68-.096-2.544-.24a13.104 13.104 0 01-2.448-.672 10.228 10.228 0 01-2.208-.96c-.672-.384-1.248-.768-1.728-1.2l1.728-2.736c.192-.336.48-.576.768-.768.288-.192.624-.288 1.104-.288.432 0 .816.096 1.152.288.336.192.72.432 1.152.624.384.24.864.432 1.392.672.528.144 1.2.24 2.016.24.576 0 1.056-.048 1.44-.144.432-.144.768-.288 1.008-.528.24-.192.384-.432.528-.672.096-.24.144-.528.144-.768 0-.528-.144-.912-.48-1.248-.336-.336-.816-.576-1.344-.816-.528-.24-1.152-.48-1.872-.672a52.486 52.486 0 01-2.112-.672c-.72-.24-1.44-.576-2.112-.912-.72-.384-1.344-.816-1.872-1.392-.528-.528-.96-1.2-1.296-2.016-.336-.816-.528-1.776-.528-2.928 0-1.056.192-2.016.624-2.976a6.239 6.239 0 011.824-2.496c.816-.768 1.824-1.296 3.072-1.728 1.248-.432 2.688-.672 4.32-.672.912 0 1.776.096 2.592.24.864.192 1.632.384 2.4.72.72.288 1.392.624 2.016 1.008.576.384 1.104.816 1.584 1.248l-1.68 2.64z" fill="#7c7c7c" fillRule="nonzero"/>
						<path d="M602.971 57.996c1.632 0 3.168.288 4.512.816 1.392.48 2.544 1.248 3.552 2.208.96.96 1.728 2.16 2.304 3.6.528 1.392.816 3.024.816 4.8 0 .576 0 1.008-.048 1.344-.048.384-.144.672-.288.864-.144.192-.288.336-.528.432-.192.096-.48.096-.816.096h-14.352c.24 2.112.864 3.6 1.92 4.56 1.008.912 2.304 1.392 3.936 1.392.864 0 1.632-.096 2.208-.336a9.399 9.399 0 001.68-.672l1.344-.672c.432-.24.864-.336 1.296-.336.576 0 1.056.192 1.344.624l2.16 2.688c-.768.864-1.584 1.584-2.496 2.16-.864.528-1.776.96-2.736 1.296-.912.288-1.872.528-2.784.624-.96.144-1.824.192-2.688.192-1.728 0-3.36-.288-4.896-.864-1.536-.576-2.88-1.392-4.032-2.544-1.152-1.104-2.016-2.496-2.688-4.176-.672-1.632-1.008-3.6-1.008-5.808 0-1.632.288-3.216.864-4.704a12.35 12.35 0 012.448-3.936c1.056-1.104 2.352-1.968 3.84-2.64 1.536-.672 3.216-1.008 5.136-1.008zm.144 5.136c-1.44 0-2.544.432-3.312 1.248-.816.816-1.344 1.968-1.584 3.456h9.264c0-.576-.096-1.152-.24-1.728a4.284 4.284 0 00-.72-1.488c-.336-.432-.816-.768-1.344-1.056-.576-.288-1.248-.432-2.064-.432z" fill="#7c7c7c" fillRule="nonzero"/>
						<path d="M636.379 64.284c-.24.288-.432.528-.624.672-.24.144-.528.24-.912.24s-.72-.096-1.008-.288a7.235 7.235 0 01-.96-.576 17.166 17.166 0 00-1.296-.576c-.528-.192-1.152-.24-1.872-.24-.912 0-1.68.144-2.352.48-.672.336-1.2.816-1.632 1.44-.432.624-.768 1.392-.96 2.304-.24.912-.336 1.92-.336 3.024 0 2.4.48 4.224 1.392 5.52.912 1.296 2.16 1.92 3.792 1.92.864 0 1.536-.096 2.064-.336.48-.192.912-.432 1.296-.72.336-.24.672-.48.96-.72.288-.24.672-.336 1.104-.336.576 0 1.056.192 1.344.624l2.16 2.688a14.144 14.144 0 01-2.4 2.16c-.864.528-1.728.96-2.64 1.296-.864.288-1.776.528-2.64.624-.912.144-1.776.192-2.64.192-1.536 0-2.976-.288-4.368-.864a10.53 10.53 0 01-3.648-2.496c-1.056-1.104-1.872-2.448-2.496-4.08-.624-1.584-.912-3.408-.912-5.472 0-1.776.288-3.456.816-4.992.528-1.584 1.296-2.928 2.352-4.08 1.056-1.152 2.352-2.016 3.888-2.688 1.536-.672 3.36-1.008 5.376-1.008 1.968 0 3.648.336 5.136.96 1.488.624 2.784 1.536 3.984 2.736l-1.968 2.592z" fill="#7c7c7c" fillRule="nonzero"/>
					</g>
					<g>
						<path d="M268 59h-34v-6.6c0-2.42 2.04-4.4 4.533-4.4h9.52c1.134 0 2.267.44 3.174 1.32l3.173 3.08H268V59z" fill="#bd1127" fillRule="nonzero"/>
						<path d="M268.833 63H234v32.375c0 2.544 2.063 4.625 4.583 4.625h45.834c2.52 0 4.583-2.081 4.583-4.625v-27.75h-13.75c-2.98 0-5.5-1.85-6.417-4.625z" fill="#bd1127" fillRule="nonzero"/>
						<path d="M298 47.25v13.5c0 1.35-1.136 2.25-2.273 2.25h-20.454c-1.137 0-2.273-.9-2.273-2.25v-13.5c0-1.35 1.136-2.25 2.273-2.25h2.272v-4.5c0-2.475 2.046-4.5 4.546-4.5h6.818c2.5 0 4.546 2.025 4.546 4.5V45h2.272c1.137 0 2.273.9 2.273 2.25zm-9.233-6.75c0-.25-.234-.5-.467-.5h-5.833c-.234 0-.467.25-.467.5V45h7v-4.5h-.233z" fill="#ffb116" fillRule="nonzero"/>
						<path d="M258.05 88.785h-2.62c-.3 0-.54-.06-.72-.2-.2-.14-.32-.32-.4-.54l-.86-2.54h-5.54l-.86 2.54c-.06.2-.2.36-.38.52-.2.14-.44.22-.72.22h-2.64l5.64-14.58h3.46l5.64 14.58zm-9.36-5.58h3.98l-1.34-3.98c-.08-.26-.18-.56-.3-.9-.12-.34-.24-.72-.34-1.12-.12.42-.22.8-.34 1.14-.12.34-.22.64-.32.9l-1.34 3.96z" fill="#ffb116" fillRule="nonzero"/>
						<path d="M268.61 74.205v2.6h-5.98v3.4h4.58v2.5h-4.58v3.48h5.98v2.6h-9.38v-14.58h9.38z" fill="#ffb116" fillRule="nonzero"/>
						<path d="M278.65 77.165c-.1.16-.2.28-.32.36-.1.08-.24.12-.42.12-.16 0-.32-.04-.5-.14s-.38-.2-.6-.32c-.22-.12-.48-.22-.76-.32s-.62-.16-.98-.16c-.64 0-1.1.14-1.42.42-.3.26-.46.62-.46 1.08 0 .3.1.54.28.74.18.2.44.36.74.5s.64.26 1.04.38c.38.12.78.26 1.2.4.4.14.8.3 1.18.5.4.2.74.44 1.04.74.3.3.56.66.74 1.1.18.42.28.94.28 1.54 0 .68-.12 1.32-.36 1.9-.22.58-.56 1.1-1 1.54-.44.44-1 .78-1.64 1.02-.66.26-1.4.38-2.22.38-.46 0-.92-.04-1.38-.14-.48-.1-.94-.22-1.38-.4-.42-.16-.84-.36-1.22-.6-.4-.24-.74-.5-1.02-.78l1-1.58c.08-.12.18-.22.32-.3.12-.06.26-.1.42-.1.2 0 .4.06.6.18.2.14.44.28.7.42.26.16.54.3.88.42.34.14.72.2 1.18.2.62 0 1.1-.14 1.44-.4.32-.28.5-.7.5-1.3 0-.34-.1-.6-.28-.82-.18-.22-.44-.4-.74-.54-.3-.12-.64-.26-1.02-.36-.4-.1-.8-.22-1.2-.36-.4-.12-.8-.28-1.18-.48-.4-.18-.74-.44-1.04-.74-.3-.32-.56-.7-.74-1.16-.18-.46-.28-1.04-.28-1.72 0-.54.12-1.08.32-1.6.24-.52.56-.98.98-1.4.42-.4.96-.72 1.58-.96.62-.24 1.32-.38 2.12-.38.44 0 .88.04 1.3.12.42.06.84.16 1.2.3.4.14.74.3 1.08.5.32.18.62.4.88.64l-.84 1.56z" fill="#ffb116" fillRule="nonzero"/>
					</g>
					<g>
						<path d="M376.711 69.793l-26.63 18.633c-2.005 1.433-4.582 0-4.582-2.293V76.1h-28.635c-1.719 0-2.864-1.147-2.864-2.867V61.767c0-1.72 1.145-2.867 2.864-2.867h28.635V48.867c0-2.293 2.577-3.726 4.582-2.293l26.63 18.633c1.719 1.146 1.719 3.44 0 4.586z" fill="#ffb116" fillRule="nonzero"/>
					</g>
				</g>
				<g>
					<path d="M743 329h86v-55h-86v55zm-18 8.8v-73.6c0-4.907 4.305-9.2 9.225-9.2h104.55c4.92 0 9.225 4.293 9.225 9.2v73.6c0 4.907-4.305 9.2-9.225 9.2h-104.55c-4.92 0-9.225-4.293-9.225-9.2zM848 388c0-1.8-1.2-3-3-3h-6c-1.8 0-3 1.2-3 3v6c0 1.8 1.2 3 3 3h6c1.8 0 3-1.2 3-3v-6zm-80-7c0-1.8-1.233-3-3.083-3h-30.834c-1.85 0-3.083 1.2-3.083 3v6c0 1.8 1.233 3 3.083 3h30.834c1.85 0 3.083-1.2 3.083-3v-6zm92-11.813v30.625c0 4.9-4.317 9.188-9.25 9.188h-129.5c-4.933 0-9.25-4.287-9.25-9.188v-30.625c0-4.9 4.317-9.187 9.25-9.187h129.5c4.933 0 9.25 4.287 9.25 9.188v-.001z" fill="#7c7c7c" fillRule="nonzero"/>
				</g>
				<defs>
					<radialGradient id="_Radial1" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(105 104.88) scale(104.38)">
						<stop offset="0" stopColor="#fff"/>
						<stop offset="1" stopColor="#ebf2f2"/>
					</radialGradient>
					<radialGradient id="_Radial2" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(85 0 0 85 105 105.5)">
						<stop offset="0" stopColor="#fff"/>
						<stop offset="1" stopColor="#ebf2f2"/>
					</radialGradient>
					<radialGradient id="_Radial3" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="matrix(65 0 0 65 105 105)">
						<stop offset="0" stopColor="#fff"/>
						<stop offset="1" stopColor="#ebf2f2"/>
					</radialGradient>
				</defs>
			</svg>
		</div>
	);
}

export default SecurityContent;
