import React from "react";
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import './Signpost.scss';

function Signpost({
	absolute,
	prev,
	next,
}) {
	let rootClass = "signpost";
	if (absolute) {
		rootClass += ' signpost--absolute'
	}
	return (
		<div className={rootClass}>
			<Link to={prev.to} className="signpost__link">
				<svg>
					<use xlinkHref="#arrow"/>
				</svg>
				{prev.title}
			</Link>
			<Link to={next.to} className="signpost__link">
				{next.title}
				<svg>
					<use xlinkHref="#arrow"/>
				</svg>
			</Link>
		</div>
	);
}

Signpost.propTypes = {
	absolute: PropTypes.bool,
	prev: PropTypes.shape({
		to: PropTypes.string.isRequired,
		title: PropTypes.string.isRequired,
	}).isRequired,
	next: PropTypes.shape({
		to: PropTypes.string.isRequired,
		title: PropTypes.string.isRequired,
	}).isRequired,
};

Signpost.defaultProps = {
	absolute: false,
};

export default Signpost;
