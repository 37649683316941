import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { extend, useThree, useFrame } from 'react-three-fiber';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

extend({ OrbitControls });

const Controls = ({
	noPan,
	noRotate,
	noZoom,
 fixedRotation,
 position,
}) => {
	const controls = React.useRef();
	const { camera, gl } = useThree();

	useEffect(() => {
		if (controls.current) {
			controls.current.reset();
		}
	}, [controls, position]);

	useFrame(() => {
		controls.current.update();
	});

	return (
		<orbitControls
			ref={controls}
			zoomSpeed={0.5}
			minPolarAngle={fixedRotation ? Math.PI * 0.46 : 0}
			maxPolarAngle={fixedRotation ? Math.PI * 0.46 : Math.PI}
			enablePan={!noPan}
			enableRotate={!noRotate}
			enableZoom={!noZoom}
			args={[camera, gl.domElement]}
		/>
	);
};

Controls.propTypes = {
	noPan: PropTypes.bool,
	noRotate: PropTypes.bool,
	noZoom: PropTypes.bool,
	fixedRotation: PropTypes.bool,
	position: PropTypes.arrayOf(PropTypes.number),
};

Controls.defaultProps = {
	noPan: false,
	noRotate: false,
	noZoom: false,
	fixedRotation: false,
	position: null,
};

export default Controls;
