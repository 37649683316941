import React, {Suspense, useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import { Canvas, useFrame, useThree } from 'react-three-fiber';
import Controls from './Controls';
import Mesh from "./Mesh";
import MeshPerf from "./MeshPerf";
import './Model.scss';
import fallback from '../../images/fallback.png';

function Camera(props) {
	const ref = useRef();
	const { setDefaultCamera } = useThree();
	useEffect(() => void setDefaultCamera(ref.current), [setDefaultCamera])
	useFrame(() => ref.current.updateMatrixWorld());
	return <perspectiveCamera ref={ref} {...props} />;
}

function Model({ page }) {
	const [loaded, setLoaded] = useState(false);
	const [perfLoaded, setPerfLoaded] = useState(false);
	const [isFixedRotation, setIsFixedRotation] = useState(false);
	const [noPan, setNoPan] = useState(false);
	const [noRotate, setNoRotate] = useState(false);
	const [noZoom, setNoZoom] = useState(false);

	useEffect(() => {
		if (page === '/market-leader' || page === '/mounting' ) {
			setNoPan(true);
			setNoRotate(false);
			setNoZoom(false);
			setIsFixedRotation(true);
		} else if (page === '/performance') {
			setNoPan(true);
			setNoRotate(false);
			setNoZoom(false);
			setIsFixedRotation(true);
		} else if (page === '/reliability') {
			setNoPan(true);
			setNoRotate(false);
			setNoZoom(false);
			setIsFixedRotation(true);
		} else if (page === '/connectivity') {
			setNoPan(true);
			setNoRotate(false);
			setNoZoom(true);
			setIsFixedRotation(false);
		} else if (page === '/security') {
			setNoPan(true);
			setNoRotate(true);
			setNoZoom(true);
			setIsFixedRotation(true);
		} else {
			setNoPan(true);
			setNoRotate(false);
			setNoZoom(false);
			setIsFixedRotation(false);
		}
	}, [page]);

	return (
		<>
			<Canvas>
				<Camera far={1000} fov={50} near={0.1} z={-100} zoom={1} position={[0, 15, 120]}>
					<directionalLight
						intensity={(page === '/connectivity') ? 0 : (page === '/performance') ? 0.25 : 0.4}
						color={0xffffff}
					/>
				</Camera>
				<Controls
					noPan={noPan}
					noRotate={noRotate}
					noZoom={noZoom}
					fixedRotation={isFixedRotation}
					position={[0, 15, 120]}
				/>
				<ambientLight
					intensity={(page === '/connectivity') ? 0.2 : 0.1}
					color={0xffffff}
				/>
				<directionalLight
					intensity={(page === '/connectivity') ? 0.1 : 0}
					color={0xffffff}
					position={[100, 1, 90]}
				/>
				<directionalLight
					intensity={(page === '/connectivity') ? 0.1 : 0}
					color={0xffffff}
					position={[-100, 1, 90]}
				/>
				<Suspense fallback={null}>
					<Mesh
						setLoaded={setLoaded}
						page={page}
					/>
				</Suspense>
				<Suspense fallback={null}>
					<MeshPerf
						setLoaded={setPerfLoaded}
						page={page}
					/>
				</Suspense>
				{/*<axesHelper args={[200, 200, 200]} />*/}
			</Canvas>
			{(!loaded && page !== '/performance') && (
				<>
					<img src={fallback} alt="ripex" className="model-fallback"/>
					<div className="modal-loader" />
				</>
			)}
			{(!perfLoaded && page === '/performance') && (
				<>
					<img src={fallback} alt="ripex" className="model-fallback"/>
					<div className="modal-loader" />
				</>
			)}
		</>
	);
}

Model.propTypes = {
	page: PropTypes.string.isRequired,
};

export default Model;
