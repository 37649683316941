import React from "react";
import Signpost from "../components/Signpost/Signpost";

function Reliability() {
	return (
		<div className="text-side">
			<h1>Reliability</h1>
			<ul className="list">
				<li>
					Industrial hardened design -40 to +70 °C
				</li>
				<li>
					Each unit tested in climatic chambre
				</li>
				<li>
					MTBF > 100 years
				</li>
				<li>
					Backup routes
				</li>
			</ul>
			<Signpost prev={{ to: '/security', title: 'Security'}} next={{ to: '/connectivity', title: 'Connectivity'}} />
		</div>
	);
}

export default Reliability;
