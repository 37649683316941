import React, { useRef, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import * as THREE from 'three';
import { useFrame, useLoader } from 'react-three-fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import './Model.scss';

function MeshPerf({
 setLoaded,
	page,
}) {
	const [animateY, setAnimateY] = useState(false);
	const [rotationX, setRotationX] = useState(0);
	const [rotationY, setRotationY] = useState(0);
	const [rotationZ, setRotationZ] = useState(0);
	const [scaler1, setScaler1] = useState(380);
	const [scaler2, setScaler2] = useState(380);
	const [scaler3, setScaler3] = useState(380);
	const [scaler4, setScaler4] = useState(380);
	const [scaler5, setScaler5] = useState(380);
	const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
	const mesh = useRef();

	const objUrl1 = process.env.PUBLIC_URL + '/vrch.glb';
	const objUrl2 = process.env.PUBLIC_URL + '/radio.glb';
	const objUrl3 = process.env.PUBLIC_URL + '/dno.glb';
	const objUrl4 = process.env.PUBLIC_URL + '/modem.glb';
	const objUrl5 = process.env.PUBLIC_URL + '/spodni_kryt.glb';

	const object1 = useLoader(GLTFLoader, objUrl1)?.scene;
	const object2 = useLoader(GLTFLoader, objUrl2)?.scene;
	const object3 = useLoader(GLTFLoader, objUrl3)?.scene;
	const object4 = useLoader(GLTFLoader, objUrl4)?.scene;
	const object5 = useLoader(GLTFLoader, objUrl5)?.scene;

	useEffect(() => {
		const updateDimensions = () => {
			setViewportWidth(window.innerWidth);
		}

		window.addEventListener("resize", updateDimensions);
		return () => window.removeEventListener("resize", updateDimensions);
	}, []);

	const scalerSize = (size, obj) => {
		let multiplicator = 0.26;
		if (size < 1056) {
			multiplicator = 0.9;
		}
		let result = size * multiplicator > 500 ? 500 : size * multiplicator;

		if (obj === 2 || obj === 3 || obj === 4 ) {
			multiplicator = 0.250;
			if (size < 1056) {
				multiplicator = 0.853;
			}
			if (size * multiplicator > 479) {
				result = 479;
			} else {
				result = size * multiplicator;
			}
		}

		return result;
	}

	useEffect(() => {
		if (object1 && object2 && object3 && object4 && object5) {
			setLoaded(true);
		}
	}, [object1, object2, object3, object4, object5, setLoaded]);

	useEffect(() => {
		if (mesh.current) {
			mesh.current.rotation.y = 0;
		}

		setAnimateY(true);
		setRotationX(0);
		setRotationY(0);
		setRotationZ(0);
		setScaler1(scalerSize(viewportWidth, 1));
		setScaler2(scalerSize(viewportWidth, 2));
		setScaler3(scalerSize(viewportWidth, 3));
		setScaler4(scalerSize(viewportWidth, 4));
		setScaler5(scalerSize(viewportWidth, 5));
	}, [page, viewportWidth]);

	useFrame(() => {
		if (animateY && mesh.current) {
			mesh.current.rotation.y += 0.005;
		}
	});

	const onClick = useCallback(
		e => {
			e.stopPropagation();
			setAnimateY(false);
		},
		[setAnimateY]
	);

	const forceReposition = useCallback(() => {
		if (mesh.current) {
			mesh.current.rotation.y = 0;
		}

		object1.rotation.x = rotationX;
		object1.rotation.y = rotationY;
		object1.rotation.z = rotationZ;
		object1.scale.set(scaler1, scaler1, scaler1);
		object2.rotation.x = rotationX;
		object2.rotation.y = rotationY + Math.PI;
		object2.rotation.z = rotationZ;
		object2.scale.set(scaler2, scaler2, scaler2);
		object3.rotation.x = rotationX;
		object3.rotation.y = rotationY;
		object3.rotation.z = rotationZ;
		object3.scale.set(scaler3, scaler3, scaler3);
		object4.rotation.x = rotationX;
		object4.rotation.y = rotationY + Math.PI;
		object4.rotation.z = rotationZ;
		object4.scale.set(scaler4, scaler4, scaler4);
		object5.rotation.x = rotationX;
		object5.rotation.y = rotationY;
		object5.rotation.z = rotationZ;
		object5.scale.set(scaler5, scaler5, scaler5);

		const box1 = new THREE.Box3().setFromObject(object1);
		const center1 = new THREE.Vector3();
		box1.getCenter(center1);
		object1.position.sub(center1);

		const box2 = new THREE.Box3().setFromObject(object2);
		const center2 = new THREE.Vector3();
		box2.getCenter(center2);
		object2.position.sub(center2);

		const box3 = new THREE.Box3().setFromObject(object3);
		const center3 = new THREE.Vector3();
		box3.getCenter(center3);
		object3.position.sub(center3);

		const box4 = new THREE.Box3().setFromObject(object4);
		const center4 = new THREE.Vector3();
		box4.getCenter(center4);
		object4.position.sub(center4);

		const box5 = new THREE.Box3().setFromObject(object5);
		const center5 = new THREE.Vector3();
		box5.getCenter(center5);
		object5.position.sub(center5);

		object1.position.y = object1.position.y + 20;
		object2.position.y = object2.position.y + 10;
		object3.position.y = object3.position.y + 0;
		object4.position.y = object4.position.y - 10;
		object5.position.y = object5.position.y - 20;
	}, [object1, object2, object3, object4, object5, rotationX, rotationY, rotationZ, scaler1, scaler2, scaler3, scaler4, scaler5]);

	useEffect(() => forceReposition(), [forceReposition, object1, object2, object3, object4, object5, rotationX, rotationY, rotationZ, scaler1, scaler2, scaler3, scaler4, scaler5]);
	useEffect(() => forceReposition(), [forceReposition, object1, object2, object3, object4, object5, rotationX, rotationY, rotationZ, scaler1, scaler2, scaler3, scaler4, scaler5]);

	return (
		<mesh
			ref={mesh}
			onClick={e => onClick(e)}
			onPointerDown={e => onClick(e)}
		>
			{(page === '/performance') ? (
				<>
					<primitive object={object1} />
					<primitive object={object2} />
					<primitive object={object3} />
					<primitive object={object4} />
					<primitive object={object5} />
				</>
			) : null}
		</mesh>
	);
}

MeshPerf.propTypes = {
	animateY: PropTypes.bool,
	rotationX: PropTypes.number,
	rotationY: PropTypes.number,
	setLoaded: PropTypes.func.isRequired,
	fixedScaler: PropTypes.number,
	page: PropTypes.string.isRequired,
};

MeshPerf.defaultProps = {
	animateY: false,
	rotationX: 20,
	rotationY: -10,
	fixedScaler: null,
};

export default MeshPerf;
