import React, { useEffect, useState } from "react";
import Signpost from '../Signpost/Signpost';
import './ConnectivityContent.scss';
import card from '../../images/card.png';

function ConnectivityContent() {
	const [isCardVisible, setIsCardVisible] = useState(false);
	useEffect(() => {
		setIsCardVisible(true);
	}, [setIsCardVisible]);
	return (
		<div className="connectivity-content">
			<div className="connectivity-content__model">
				<div className="connectivity-content__item connectivity-content__item--antenna">
					Antennas
					<svg>
						<use xlinkHref="#line-corner"/>
					</svg>
					<svg>
						<use xlinkHref="#line-corner"/>
					</svg>
				</div>
				<div className="connectivity-content__item connectivity-content__item--puts">
					Digital Inputs<br />
					and Outputs
					<svg>
						<use xlinkHref="#line-corner"/>
					</svg>
				</div>
				<div className="connectivity-content__item connectivity-content__item--usb">
					USB
					<svg>
						<use xlinkHref="#line-corner"/>
					</svg>
				</div>
				<div className="connectivity-content__item connectivity-content__item--sfp">
					SFP/Ethernet
					<svg>
						<use xlinkHref="#line-corner"/>
					</svg>
				</div>
				<div className="connectivity-content__item connectivity-content__item--ethernet">
					4× Ethernet
					<svg>
						<use xlinkHref="#line-corner"/>
					</svg>
				</div>
				<div className="connectivity-content__item connectivity-content__item--power">
					Power Connector
					<svg>
						<use xlinkHref="#line-corner"/>
					</svg>
				</div>
				<div className="connectivity-content__item connectivity-content__item--serial">
					RS232/RS485
					<svg>
						<use xlinkHref="#line-corner"/>
					</svg>
				</div>
				<div className="connectivity-content__item connectivity-content__item--aux">
					Aux Antenna (GPS)
					<svg>
						<use xlinkHref="#line-corner"/>
					</svg>
				</div>
			</div>
			<div className={`connectivity-content__card ${isCardVisible ? 'is-visible' : ''}`}>
				<img src={card} alt="mPCIe card" />
				<div className="connectivity-content__item connectivity-content__item--card">
					mPCI Expansion slot<br />
					Cellular, 2× Serial Ports, GPS, …
					<svg>
						<use xlinkHref="#line"/>
					</svg>
				</div>
			</div>
			<Signpost prev={{ to: '/reliability', title: 'Reliability'}} next={{ to: '/mounting', title: 'Mounting'}} absolute />
		</div>
	);
}

export default ConnectivityContent;
